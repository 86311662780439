import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CatalogItemViewState } from './types';
import { catalogItemViewReducerPath } from './const';

const initialState: CatalogItemViewState = {
  isListWithAssesmentInstructionCompleted: false,
  isListWithAssesmentInstructionCompletedByRegisteredUser: false,
};

export const catalogItemViewSlice = createSlice({
  name: catalogItemViewReducerPath,
  initialState,
  reducers: {
    setListInstructionCompleted(state, action: PayloadAction<CatalogItemViewState['isListWithAssesmentInstructionCompleted']>) {
      state.isListWithAssesmentInstructionCompleted = action.payload;
    },
    setListInstructionCompletedByRegisteredUser(
      state,
      action: PayloadAction<CatalogItemViewState['isListWithAssesmentInstructionCompletedByRegisteredUser']>,
    ) {
      state.isListWithAssesmentInstructionCompletedByRegisteredUser = action.payload;
    },
  },
});

export const catalogItemViewReducerConfig = {
  [catalogItemViewReducerPath]: catalogItemViewSlice.reducer,
};
