import { ConfigureStoreOptions, configureStore } from '@reduxjs/toolkit';
import { localizationReducerPath, localizationSlice } from '@cp/shared/localization';
import { authenticationReducerPath, authenticationSlice, coreApi } from '@cp/shared/api/core';
import { modalViewReducerConfig } from '@cp/ds/src/components/modalView';
import { dialogReducerConfig } from '@cp/entities/dialog';
import { clientFavoritesReducerConfig } from '@cp/entities/client/current/favorite';
import { clientTrainingReducerConfig } from '@youhelp/client.web/features/client/training';
import { clientRegistrationReducerConfig } from '@youhelp/client.web/features/client/registration';
import { mainResumeReducerConfig } from '@youhelp/client.web/entities/mainResume';
import { catalogReducerConfig } from '@youhelp/client.web/features/catalog';
import { modal } from '@youhelp/client.web/redux/slices/modal';
import { locationCitySelectionReducerConfig } from '@youhelp/client.web/features/location';
import { catalogItemViewReducerConfig } from '@youhelp/client.web/features/catalogItem/view';

export const configureAppStore = ({ reducer, ...options }: Partial<Omit<ConfigureStoreOptions, 'middleware'>>) => {
  return configureStore({
    reducer: {
      modal,
      ...catalogReducerConfig,
      [coreApi.reducerPath]: coreApi.reducer,
      [authenticationReducerPath]: authenticationSlice.reducer,
      [localizationReducerPath]: localizationSlice.reducer,
      ...clientTrainingReducerConfig,
      ...clientRegistrationReducerConfig,
      ...dialogReducerConfig,
      ...clientFavoritesReducerConfig,
      ...mainResumeReducerConfig,
      ...catalogItemViewReducerConfig,
      ...modalViewReducerConfig,
      ...locationCitySelectionReducerConfig,
      ...reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(coreApi.middleware),
    ...options,
  });
};
